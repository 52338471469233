@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90px;
  box-shadow: rgba(50, 50, 93, 0.23) 0px 30px 30px -20px;

  /*box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
}
.logo {
  height: 56px;
  position: relative;
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}

.nav-links li {
  color: black;
  font-size: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.hero {
  text-align: center;
  margin-top: 15%;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
  width: 10px;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  background: none;
}
@media (max-width: 980px) {
  .navbar{
    height: 90px;
  }
  .logo {
    display: flex;
    position: absolute;
    left: 10px;
    height: 45px;
 
  }
  .nav-links {
    display: none;
    
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    left: 0;
    top: 12%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
    z-index: 2;
  }
  ul li {
    padding: 20px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 43px;
   
  }
  .hero h1 {
    font-size: 50px;
  }
  svg {
    height: 24px;
    width: 24px;
}
}
