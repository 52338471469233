*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.carousel{
    background-color: #fcfcfc;
    color: #080808;
    width: 100%;
}

.box{
    background-color: rgb(255, 255, 255);
    height: 290px;
    margin: 0px;
}

.box h3{
    text-align: center;
} 


.slick-dots li.slick-active button:before{
    color: lightgreen;
}

.click-dots li button:before{
    color: #f80606 !important;
    font-size: 1rem !important;
    top:2rem !important;
}
button.slick-arrow.slick-next {
    right: 0px;
}



button.slick-arrow.slick-prev.slick-disabled{
    width: 44px;
    height: 44px;
    z-index: 1;
    background-color: grey;
    left: 20px;
}

button.slick-arrow.slick-next {
    width: 44px;
    height: 44px;
    z-index: 1;
    background-color: gray;
    right: 18px;
}

button.slick-arrow.slick-prev {
    width: 44px;
    height: 44px;
    z-index: 1;
    background-color: gray;
    left: 20px;
}

.brands_slider{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.brands_slider .brands{
    list-style: none;
    width: 335em;
    display: flex;
    animation: slide_Left 10s linear infinite;
    margin-right: 50px;
}
@keyframes slide_Left {
    100%{
        transform: translateX(-50%);
    }
}

@media (min-width: 1440px) {
    .sc-eDLKkx.iSOgXa {
        height: 30vh;
    }
    .sc-eDLKkx.hMzrAq {
        height: 35vh;
    }
    
.sc-guDLey.dYPMoJ {
    margin-bottom: 30px;
}


}

@media (max-width: 786px) {
    button.slick-arrow.slick-next {
        right: 22px;
    }
    img.sc-iBdnpw.jLYbrn {
        padding: 0px;
    }
    .sc-hLQSwg.kJPsWj {
        margin-bottom: 30px;
    }
    .relative.flex.flex-col.items-center.p-4.sm\:max-w-xl {
        top:125px
    }
    section.min-h-90.relative.flex.flex-1.shrink-0.items-center.justify-center.overflow-hidden.bg-gray-2000.py-10.shadow-lg.md\:py-20.xl\:py-48 {
        padding-bottom: 17.5rem;
    }
    .sc-eDLKkx.hMzrAq {
        width: 100%;
    }
    .sc-jTQCzO.cjsLCr {
        width: 160%;
    }

}   